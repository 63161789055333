import React from "react"
import styles from './styles.module.css'

const SummaryBanner = ({title, text, contact, highlights, button}) => (
  <section className={styles.container}>
    <div className={styles.content}>
      <h3>{title}</h3>
      <p>{text}</p>
      {contact && Object.keys(contact).map((key, index) => (
        <div key={index} >
          <span>{key}</span>
          <span>{contact[key]}</span>
        </div>
      ))}
      {button &&
      <button className={'primary'} onClick={button.action}>
        {button.text}
      </button>}
    </div>
    <div className={styles.divider}/>
    <div className={styles.highlights}>
      {Object.keys(highlights).map((key, index) => (
        <div key={index}>
          <span>{key}</span>
          <span>{highlights[key]}</span>
        </div>
      ))}
    </div>
  </section>
)
export default SummaryBanner
